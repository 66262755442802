import { notification } from "antd";

import { DURATION_NOTIFICATION } from "@app/constants/constants";

interface NotificationProps {
  type: "success" | "info" | "warning" | "error";
  message: string | React.ReactNode;
  placement?:
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
}

export const openNotification = ({
  type,
  message,
  placement = "top",
}: NotificationProps) => {
  return notification[type]({
    message,
    placement,
    duration: DURATION_NOTIFICATION,
  });
};
